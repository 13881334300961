(function () {
  'use strict';

  angular
    .module('neo.home.licenseSearch')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.licenseSearch', {
        url: '/license-search',
        templateUrl: 'home/license-search/license-search.tpl.html',
        controller: 'LicenseSearchCtrl',
        controllerAs: 'vm',
        resolve:{
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          licenseTypes: function (LicenseTypes) {
            return LicenseTypes.query().$promise;
          },
          programmes: function (Programmes) {
            return Programmes.query().$promise;
          }
        }
      });
  }
}());
